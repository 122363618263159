import React from "react";
import Routes from "./router/Routes";
import { LanguageProvider } from "./contexts/LanguageContext";
import { wagmiConfig } from "./wagmiConfig";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const client = new QueryClient();

  return (
    <QueryClientProvider client={client}>
      <WagmiProvider config={wagmiConfig}>
        <LanguageProvider>
          <Routes />
        </LanguageProvider>
      </WagmiProvider>
    </QueryClientProvider>
  );
}

export default App;
