import { http, createConfig } from "wagmi";
import { bsc } from "viem/chains";
import { walletConnect, injected } from "wagmi/connectors";

export const settings = {
  projectId: "b03e44f1ce4c0884e481da899a75808f",
  metadata: {
    name: "XECO",
    description: "XECO WalletConnect",
    url: "https://bnbgift.online/",
    icons: ["https://bnbgift.online/favicon.png"],
  },
};

export const chains = [bsc];

const connectors = [];
connectors.push(
  walletConnect({
    projectId: settings.projectId,
    metadata: settings.metadata,
    showQrModal: false,
    featuredWalletIds: [
        "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
        "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96"
    ],
    enableCoinbase: false,
    allWallets: "HIDE",
  })
);
connectors.push(injected({ shimDisconnect: true }));

export const wagmiConfig = createConfig({
  chains,
  transports: {
    [bsc.id]: http(),
  },
  connectors: connectors,
});
