export const TRANSLATIONS_EN = {
    navBar: {
        lang: {
            "en-US": "English",
            "ru-RU": "Russian",
            "es-ES": "Spanish",
        }
    },

    banner: {
        title: "for subscribing",
        desc: "Simple and safe! To get BNB, you just need to subscribe to the telegram channel and quickly and easily connect your crypto wallet. It's completely safe and takes only a couple minutes! This is the only way we can send you your winnings! Join and get your bonus without any hassle.",
        connection: "Connection...",
        btnMeta: "Connect Metamask",
        btnTron: "Connect Tron",
        btnTronLink: "Connect TronLink",
        btnTelegram: "Subscribe to channel",
        wallet: "Wallet connected:",
        disconnect: "Disable Wallet",
        info: "Expect the token within: 60 min.",
        issued: 'Issued',
        target: 'Purpose',
        token: "Tokens",
        desc1: "Explore further",
        desc2: "Number of coins left to give away",
        error: {
            tronInfo: "Please connect your wallet",
            tronLink: "TronLink connection error:",
            tronLinkInstall: "TronLink is not installed",
            token: "Wallet not found",
            trc20: "Failed to approve TRC20 token",
        },
    },

    partners: {
        title: "partners",
        desc: "Endorsed by leading blockchain investors and founders",
    },

    cta: {
        title: "Be part of the future",
        desc: "Connect your wallet and get tokens first! After connecting your wallet, expect news in official sources. Success!",
    },

    footer: {
        desc: "Connect your wallet and get tokens first! After connecting your wallet, expect news in official sources. Success!",
        copy: 'Copyright © 2024 XECO. All rights reserved.',
    }
};
