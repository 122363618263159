import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const basePath = "footer.";

  return (
    <section className="footer">
      <div className="container">
        <Row>
          <Col lg={6} md={6} sm={12} className="text-left">
            <img
              src={require("../assets/img/logo.svg").default}
              className="logo"
              alt=""
            />
            <p>{t(`${basePath}desc`)}</p>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <div className="social">
              <a href="https://www.instagram.com/airdropbnb2024?igsh=MXV6amtrN3ZwMTU2&utm_source=qr">
                <img
                  src={require("../assets/img/social/instagram.svg").default}
                  alt=""
                />
              </a>
              <a href="https://t.me/future_global_crypto">
                <img
                  src={require("../assets/img/social/telegram.svg").default}
                  alt=""
                />
              </a>
            </div>
          </Col>
        </Row>
        <div className="copy">{t(`${basePath}copy`)}</div>
      </div>
    </section>
  );
};

export default Footer;
