import React from "react";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../contexts/LanguageContext";
import { languages } from "../i18n";

const langIcons = {
  "ru-RU": require("../assets/img/flags/ru.png"),
  "en-US": require("../assets/img/flags/en.png"),
  "es-ES": require("../assets/img/flags/es.png"),
};

const NavBar = () => {
  const { t } = useTranslation();
  const basePath = "navBar.";

  const { changeLanguage } = useLanguage();
  const activeLang = localStorage.getItem("i18nextLng");

  const handleChangeLang = (e) => {
    if (e.target.className === "dropdown-item") {
      changeLanguage(e.target.dataset.value);
    }
  };

  return (
    <nav className="navbar-custom">
      <div className="container">
        <div className="left-side">
          <a href="/" className="logo">
            <img
              className="logo"
              src={require("../assets/img/logo.svg").default}
              alt=""
            />
          </a>
        </div>
        <NavDropdown
          title={t(`${basePath}lang.${activeLang}`)}
          id="collasible-nav-dropdown"
          onClick={handleChangeLang}
        >
          {languages.map((item) => (
            <NavDropdown.Item key={item} data-value={item}>
              <img src={langIcons[item]} alt="" />
              {t(`${basePath}lang.${item}`)}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      </div>
    </nav>
  );
};

export default NavBar;
