import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CTA = () => {
  const { t } = useTranslation();
  const basePath = "cta.";

  return (
    <section className="cta">
      <Container>
        <div className="box">
          <div className="details">
            <h4>{t(`${basePath}title`)}</h4>
            <p>{t(`${basePath}desc`)}</p>
          </div>
          <div className="images">
            <img src={require("../assets/img/info.png")} alt="" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CTA;
