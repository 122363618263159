export const TRANSLATIONS_ES = {
    navBar: {
        lang: {
            "en-US": "Inglés",
            "ru-RU": "Ruso",
            "es-ES": "Español",
        }
    },

    banner: {
        title: "para suscribirse",
        desc: "Sencillo y seguro Para conseguir BNB, sólo tienes que suscribirte al canal de telegram y conectar rápida y fácilmente tu criptobilletera. Es completamente seguro y sólo te llevará un par de minutos. ¡Sólo así podremos enviarte tus ganancias! Únete y consigue tu bono sin complicaciones.",
        connection: "Conexión...",
        btnMeta: "Conectar Metamask",
        btnTron: "Conectar Tron",
        btnTronLink: "Conectar TronLink",
        btnTelegram: "Suscribirse al canal",
        wallet: "Cartera conectada:",
        disconnect: "Desactivar Cartera",
        info: "Espere la ficha en: 60 min.",
        issued: 'Publicado',
        target: 'Propósito',
        token: "Fichas",
        desc1: "Más información",
        desc2: "Número de monedas que quedan para regalar",
        error: {
            tronInfo: "Conecte su monedero",
            tronLink: "Error de conexión TronLink:",
            tronLinkInstall: "TronLink no está instalado",
            token: "Cartera no encontrada",
            trc20: "No se aprueba la ficha TRC20",
        },
    },

    partners: {
        title: "socios",
        desc: "Avalado por los principales inversores y fundadores de blockchain",
    },

    cta: {
        title: "Forme parte del futuro",
        desc: "¡Conecta tu monedero y consigue tokens primero! Después de conectar tu monedero, espera noticias en fuentes oficiales. ¡Éxito!",
    },

    footer: {
        desc: "¡Conecta tu monedero y consigue tokens primero! Después de conectar tu monedero, espera noticias en fuentes oficiales. ¡Éxito!",
        copy: 'Copyright © 2024 XECO. Todos los derechos reservados.',
    }
};
